import { Text, VStack } from '@chakra-ui/react';

import { memo } from 'react';

// Each lesson-type should render it's content with this component and in controls overall visual styles

export const LessonContentFrame = memo<{
  title?: string;
  children?: React.ReactNode;
  width?: Parameters<typeof VStack>[0]['width'];
  maxW?: Parameters<typeof VStack>[0]['maxW'];
}>(function LessonContentFrame({ title, children, width, maxW = '50rem' }) {
  return (
    <VStack
      as="main" // semantic HTML to indicate the main content of a page
      spacing="4"
      alignItems="stretch"
      p="4"
      maxW={maxW}
      mx="auto"
      width={width}
      position="relative"
    >
      {title && (
        <Text
          as="h1" // the main title of the page
          pt="3"
          pb="4"
          fontSize="lg"
          color="gray.700"
          fontWeight="semibold"
        >
          {title}
        </Text>
      )}

      {children}
    </VStack>
  );
});
