import { useEffect } from 'react';

import { request } from 'lib/request';
import { trackError } from 'lib/errorTracking';

export function useUpdateTimezone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    request({ url: '/timezone', method: 'PUT', body: { timezone } }).catch(
      trackError,
    );
  }, [timezone]);

  return timezone;
}
