import { useEffect, useState, useCallback } from 'react';

export const useLocalStorage = <V>(
  key: string,
  initValue: V,
): [V, (next: V) => void, () => void] => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    const val = localStorage.getItem(key);

    if (val) {
      try {
        setValue(JSON.parse(val));
      } catch {}
    }
  }, [key]);

  const set = useCallback(
    (next: V) => {
      setValue(next);
      localStorage.setItem(key, JSON.stringify(next));
    },
    [key],
  );

  const clear = () => {
    setValue(initValue);
    localStorage.removeItem(key);
  };

  return [value, set, clear];
};
